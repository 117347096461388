import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import { mapEdgesToNodes } from '../lib/helpers';
import { useLoadMore } from '../lib/hooks';

import localize from '../components/localize';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHero from '../components/PageHero';
import OutlineBtnLg from '../components/Buttons/OutlineBtnLg';
import PageFooter from '../components/PageFooter';
import Lightbox from '../components/Lightbox';

const GalleryPage = ({ data, pageContext, path }) => {
  const pageArray = mapEdgesToNodes(data.page);
  const pageInfo = pageArray[0];
  const imagesArray = mapEdgesToNodes(data.images);
  const { loadMoreImages } = data.galleryDictionary;
  const numOfItemsToDisplay = 5;

  const [imagesList, loadMore, hasMore, setLoadMore] = useLoadMore(
    imagesArray,
    numOfItemsToDisplay
  );

  // Handle 'load more' button click
  const handleLoadMoreClick = () => {
    setLoadMore(true);
  };

  // Set states for Lightbox component
  const [imageToShow, setImageToShow] = useState('');
  const [imageToShowIdx, setImageToShowIdx] = useState('');
  const [lightboxDisplay, setLightboxDisplay] = useState(false);

  // // Functions to set states for Lightbox component
  const showImage = (image, idx) => {
    setImageToShow(image);
    setImageToShowIdx(idx);
    setLightboxDisplay(true);
  };

  const hideLightbox = () => {
    setLightboxDisplay(false);
  };

  // Responsive breakpoints for Masonry component
  const breakpointColumnObj = {
    default: 3,
    992: 2,
    500: 1,
  };

  return (
    <>
      <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
        <SEO title={pageInfo.title} image={pageInfo.heroImg.asset.fluid} />
        <header>
          <PageHero withImage fluidImg={pageInfo.heroImg.asset.fluid} pageTitle={pageInfo.title} />
        </header>

        <Container as="section" style={{ marginTop: '4.875rem' }}>
          <Masonry
            breakpointCols={breakpointColumnObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {imagesList.map((image, index) => {
              return (
                <span
                  key={image.id}
                  style={{ display: 'block', cursor: 'pointer' }}
                  onClick={() => showImage(image, index)}
                >
                  <Img fluid={image.portrait.asset.fluid} />
                </span>
              );
            })}
          </Masonry>
        </Container>
        <PageFooter>
          {hasMore ? (
            <OutlineBtnLg btnText={loadMoreImages} as="button" handleClick={handleLoadMoreClick} />
          ) : (
            <OutlineBtnLg btnText={loadMoreImages} as="button" disabled />
          )}
        </PageFooter>
      </Layout>
      <Lightbox
        show={lightboxDisplay}
        handleClose={hideLightbox}
        images={imagesList}
        currentImg={imageToShow}
        currentImgIndex={imageToShowIdx}
      />
    </>
  );
};

export const query = graphql`
  query GalleryPageQuery {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    page: allSanityPage(filter: { id: { eq: "-7bdafda6-3a42-590a-afdf-4ee93cd88586" } }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          heroImg {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    images: allSanityPortrait(sort: { fields: shootingDate, order: DESC }) {
      edges {
        node {
          id
          shootingDate
          caption {
            _type
            de
            en
          }
          portrait {
            asset {
              fluid {
                aspectRatio
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    galleryDictionary: sanityDictionaryGallery {
      loadMoreImages {
        _type
        de
        en
      }
    }
  }
`;

export default localize(GalleryPage);
