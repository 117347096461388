import React, { useState } from 'react';
import Img from 'gatsby-image';
import { Modal, Container } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';

import { breakpoints } from '../theme/mediaQueries';

const Lightbox = ({ show, handleClose, images, currentImg, currentImgIndex }) => {
  const customRenderItem = (item, props) => {
    return <item.type {...item.props} />;
  };

  return (
    <div>
      <StyledModal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Container>
            <Carousel
              selectedItem={currentImgIndex}
              renderItem={customRenderItem}
              dynamicHeight={true}
              showStatus={false}
              infiniteLoop={true}
              showThumbs={false}
            >
              {images.map((image) => {
                return (
                  <div key={image.id} id={image.id}>
                    <StyledGatsbyImg
                      fluid={image.portrait.asset.fluid}
                      alt={image.caption}
                      objectFit="contain"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </div>
                );
              })}
            </Carousel>
          </Container>
        </Modal.Body>
        <Modal.Footer />
      </StyledModal>
    </div>
  );
};

const StyledModal = styled(Modal)`
  & .modal-dialog {
    max-width: none;
    width: 95vw;
    margin: 0 auto;
    height: 95vh;
    margin-top: calc((100vh - 95vh) / 2);

    & .modal-content {
      border: none;
      border-radius: 0;
      & .modal-header {
        border: none;
        border-radius: 0;
      }
      & .modal-body {
        & .control-arrow {
          color: ${(props) => props.theme.colors.white};
          display: none;

          @media (min-width: ${breakpoints.lg}px) {
            display: inline-block;
          }

          &::before {
            border-top: 16px solid transparent;
            border-bottom: 16px solid transparent;
          }

          &:hover {
            background-color: transparent;
          }
        }
        & .control-prev {
          left: 1rem;
          &::before {
            border-right: 16px solid ${(props) => props.theme.colors.white};
          }
        }

        & .control-next {
          right: 1rem;
          &::before {
            border-left: 16px solid ${(props) => props.theme.colors.white};
          }
        }

        & .slide {
          background-color: transparent;
        }
      }
      & .modal-footer {
        border: none;
        border-radius: 0;
      }
    }
  }
`;

const StyledGatsbyImg = styled(Img)`
  height: auto;
  width: auto;
  max-width: 100%;
`;

export default Lightbox;
